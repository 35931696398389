<template>
  <div>Redirecting to app...</div>
</template>

<script>
export default {
  created() {

    window.onunload = () => {
      // Regex removes leading and trailing slashes
      const redirectRote = this.$route.query.redirectRoute.replace(/^\/|\/$/g, '');
      window.opener.location = `${window.location.origin}/${redirectRote}`
    }

    // Store action is synchronuous
    this.$store.dispatch('auth/loginWithTwitter', {
      accessToken: this.$route.query.accessToken,
      refreshToken: this.$route.query.refreshToken,
      selectedCompanyId: this.$route.query.selectedCompanyId,
      userPermissions: []
    });

    // Need to get user permissions as extra call for twitter only, other auth method contains user permissions as response
    this.$http.get(`api/auth/userPermissions/${this.$route.query.selectedCompanyId}`)
      .then(response => {
        this.$store.dispatch('auth/updateUserPermissions', response.data);
        window.close()
      })
      .catch(error => this.$_notifyFailureByResponseData(error.response.data))
    

  }
}
</script>

<style>

</style>